<template>
	<div
		v-if="isBlockShown"
		:class="['panel', 'themes',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : ''
		]"
		:style="block.background_image_default.src ?
			'background-image:url(' + block.background_image_default.src + ')' : ''"
	>
		<div class="container">
			<div
				v-if="isHeaderVisible"
				:class="[
					'panel_header',
					isBrand? 'sk' : '',
				]"
			>
				<div class="row align-items-end">
					<div class="col-12">
						<h2 v-if="block.block_title === null">
							{{ $t('topics') }} {{ fetchedData.type_titles.title_genitive }}
						</h2>
						<h2 v-else>
							{{ block.block_title }}
						</h2>
						<div
							v-if="block.description"
							class="description"
							v-html="block.description"
						/>
					</div>
				</div>
				<!-- <nuxt-link class="panel_control" to="#"></nuxt-link> -->
			</div>
			<div v-if="block.items.length === 1" class="themes_block">
				<ul class="themes_list row">
					<li
						v-for="(item, index) in block.items"
						:key="index"
						class="col-12 col-md-12 col-lg-12"
					>
						<div class="theme">
							<div class="title">
								{{ item.theme }}
							</div>
							<ul class="checked_list">
								<li v-for="(sub_item, index) in item.sub_items" :key="index">
									{{ sub_item.sub_theme }}
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
			<div v-else-if="block.items.length === 2" class="themes_block">
				<ul class="themes_list row">
					<li
						v-for="(item, index) in block.items"
						:key="index"
						class="col-12 col-md-6 col-lg-6"
					>
						<div class="theme">
							<div class="title">
								{{ item.theme }}
							</div>
							<ul class="checked_list">
								<li v-for="(sub_item, index) in item.sub_items" :key="index">
									{{ sub_item.sub_theme }}
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
			<div v-else-if="block.items.length === 3" class="themes_block">
				<ul class="themes_list row">
					<li
						v-for="(item, index) in block.items"
						:key="index"
						class="col-12 col-md-12 col-lg-4"
					>
						<div class="theme">
							<div class="title">
								{{ item.theme }}
							</div>
							<ul class="checked_list">
								<li v-for="(sub_item, index) in item.sub_items" :key="index">
									{{ sub_item.sub_theme }}
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
			<div v-else-if="block.items.length === 4" class="themes_block">
				<ul class="themes_list row">
					<li
						v-for="(item, index) in block.items"
						:key="index"
						class="col-12 col-md-6 col-lg-6"
					>
						<div class="theme">
							<div class="title">
								{{ item.theme }}
							</div>
							<ul class="checked_list">
								<li v-for="(sub_item, index) in item.sub_items" :key="index">
									{{ sub_item.sub_theme }}
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
			<div v-else-if="block.items.length === 0" class="themes_block">
			</div>
			<div v-else class="themes_block">
				<ul class="themes_list row">
					<li
						v-for="(item, index) in block.items"
						:key="index"
						class="col-12 col-md-6 col-lg-6"
					>
						<div class="theme">
							<div class="title">
								{{ item.theme }}
							</div>
							<ul class="checked_list">
								<li v-for="(sub_item, index) in item.sub_items" :key="index">
									{{ sub_item.sub_theme }}
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>

			<div v-if="isFooterVisible" class="panel_footer">
				<div class="row">
					<div class="col-12">
						<dynamic-button
							v-if="!block.show_alumni_link"
							:button="block.button"
							:block-id="block.id"
							:scenario="block.scenario"
						/>
						<dynamic-button
							v-else-if="block.show_alumni_link && isAlumni"
							:button="block.button"
							:block-id="block.id"
							:scenario="block.scenario"
						/>
						<div v-else>

						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel';
import checkIsBlockShown from '~/mixins/checkIsBlockShown';

export default {
	name: 'PanelConferenceThemes',
	mixins: [panel, checkIsBlockShown],
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-themes";
</style>

<i18n lang="yaml">
ru:
  topics: "Темы"
en:
  topics: "Topics"
</i18n>
